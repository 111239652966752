import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import Avatar from '../Avatar';

const AdminPortalGrid = ({ rows, grantAccess, loading }) => {
  const formatDate = (date) => {
    const d = new Date(date);
    const formatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });
    return formatter.format(d).toString();
  };

  return (
    <div className="w-full overflow-x-auto">
      <table className="min-w-[1200px]">
        <thead className="text-gray-500 font-normal text-sm bg-gray-200">
          <tr>
            <th>User</th>
            <th>Attempt Counter</th>
            <th>First tried</th>
            <th>Last tried</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {!loading && Array.isArray(rows) && rows.length > 0 ? (
            rows.map((user, index) => (
              <tr key={index}>
                <td className="flex items-center gap-2">
                  <Avatar username={user.email} />
                  <p>{user.email}</p>
                </td>
                <td>
                  <p>{user.attemptcounter}</p>
                </td>
                <td>
                  <p>{formatDate(user.createdAt)}</p>
                </td>
                <td>
                  <p>{formatDate(user.updatedAt)}</p>
                </td>
                <td className="flex justify-center">
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => grantAccess(user.email)}
                  >
                    Grant Access
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                {Array.isArray(rows) ? (
                  'No data available'
                ) : (
                  <CircularProgress color="success" />
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AdminPortalGrid;