import React, { useState, useRef } from "react";
import userData from "../services/userData";
import { Loading } from "../pages/InterviewRoom";
import { Audio } from "react-loader-spinner";

const AppuComponent = ({ text }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [audioUrl, setAudioUrl] = useState({});
  const [fileId, setFileId] = useState({});
  const audioPlayerRef = useRef(null);

  const askAppu = async () => {
    if (!selectedLanguage) {
      return;
    }

    setIsPlaying(false);
    setLoading(true);

    try {
      const res = await userData.appuAudioCreate({
        text,
        languages: selectedLanguage,
      });
      const { data } = res;

      if (data?.success === true) {
        setFileId((prev) => ({ ...prev, [selectedLanguage]: data.file_id }));
        return data.file_id;
      } else {
        console.error("Error from server:", data.error);
        setIsPlaying(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsPlaying(false);
    } finally {
      setLoading(false);
    }
  };

  const getAppuResponse = async (file_id) => {
    setLoading(true);
    try {
      const res = await userData.appuAudioFetch(file_id);
      const blob = res.data;
      setAudioUrl((prev) => ({
        ...prev,
        [selectedLanguage]: URL.createObjectURL(blob),
      }));
    } catch (err) {
      console.log(
        "Error fetching audio: ",
        err.response?.data?.message || "Unknown error"
      );
    } finally {
      setLoading(false);
    }
  };

  const playAudio = async () => {
    try {
      const audioPlayer = audioPlayerRef.current;
      let file_id = fileId[selectedLanguage];
      if (!fileId[selectedLanguage]) {
        file_id = await askAppu();
      }
      if (!audioUrl[selectedLanguage]) {
        await getAppuResponse(file_id);
      }
      if (audioPlayer) {
        audioPlayer.load();
        audioPlayer.addEventListener("loadedmetadata", () => {
          console.log("Plying audio in next few lines...");
          setIsPlaying(true);
          audioPlayer.play();
        });
      }
    } catch (err) {
      console.log("Error loading audio:", err);
    }
  };

  const stopAudio = () => {
    const audioPlayer = audioPlayerRef.current;
    if (audioPlayer) {
      audioPlayer.pause();
      audioPlayer.currentTime = 0;
    }
    setIsPlaying(false);
  };

  return (
    <div
      id="app"
      className="flex flex-col items-center justify-center font-poppins"
    >
      {!isPlaying && !loading && (
        <img src="/appuu.png" alt="appu" className="w-36 mb-5 cursor-pointer" />
      )}

      {isPlaying && (
        <div className="grid grid-cols-1 md:grid-cols-3 items-center gap-6 p-4">
          <div className="col-span-1">
            <img src="/appu.gif" alt="appu speaking" className="w-96" />
            <p className="text-lg font-bold text-primary text-center -translate-y-8">
              Hi, I am Appu!
            </p>
          </div>
          <div className="flex flex-col gap-6 p-4 col-span-2">
            <div className="h-full rounded-2xl p-4 bg-primary/10">{text}</div>
          </div>
        </div>
      )}

      {loading && (
        <div className="text-lg text-secondary-text font-semibold mb-5 flex gap-5 justify-center items-center">
          <Loading />
          <p>Loading Appu's advice...</p>
        </div>
      )}

      {!loading && !isPlaying && (
        <div className="flex flex-col gap-5 mb-5">
          <div className="mr-5 flex gap-4 items-center">
            <label htmlFor="language-menu">Select language:</label>
            <select
              id="language-menu"
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
              className="text-lg p-2 rounded border border-gray-300 bg-white text-gray-800 cursor-pointer"
            >
              <option disabled value="">
                Select Language
              </option>
              <option value="en">English</option>
              <option value="es">Spanish</option>
              <option value="fr">French</option>
              <option value="de">German</option>
              <option value="ru">Russian</option>
              <option value="hi">Hindi</option>
              <option value="bn">Bengali</option>
              <option value="mr">Marathi</option>
              <option value="gu">Gujarati</option>
              <option value="kn">Kannada</option>
              <option value="ta">Tamil</option>
              <option value="te">Telugu</option>
              <option value="ml">Malayalam</option>
              <option value="it">Italian</option>
              <option value="pl">Polish</option>
              <option value="sv">Swedish</option>
            </select>
          </div>
          {text ? (
            <button
              onClick={playAudio}
              className=" text-white text-lg p-2 rounded-full flex items-center justify-center transition-colors duration-300 primary-button"
            >
              <p>{(fileId[selectedLanguage] ? "Ask Appu" : "Continue")}</p>
            </button>
          ) : (
            <div className="text-lg flex gap-2 items-center">
              <Audio height={40} width={40} color="blue" />
              <span>Appu is analyzing the report...</span>
            </div>
          )}
        </div>
      )}
      {isPlaying && !loading && (
        <button
          onClick={stopAudio}
          className=" text-white text-lg p-2 rounded-full flex items-center transition-colors duration-300 secondary-button"
        >
          Go Back
        </button>
      )}

      {/* Audio player */}
      <audio
        className="mt-5"
        ref={audioPlayerRef}
        controls
        onEnded={() => setIsPlaying(false)}
        style={{ display: isPlaying ? "block" : "none" }}
      >
        <source src={audioUrl[selectedLanguage]} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default AppuComponent;
