import axios from "axios";

export const base2 = "https://parinaam-fastapi.yuktii.com/";

export default axios.create({
  baseURL: base2,
  headers: {
    "Content-type": "application/json",
  },
});
