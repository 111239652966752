import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Container,
  Grid,
} from "@mui/material";
import userData from "../../services/userData";

const FeedbackTable = () => {
  const [feedbackEntries, setFeedbackEntries] = useState([]);
  const [pdfDialog, setPdfDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    fetchFeedback();
  }, []);

  const fetchFeedback = async () => {
    try {
      const res = await userData.getAllFeedback();
      console.log(res.data);
      setFeedbackEntries(res.data);
    } catch (error) {
      console.error("Error fetching feedback:", error);
    }
  };

  const openPdfDialog = (item) => {
    setSelectedItem({
      build: item.build || {},
      improve: item.improve || {},
      evaluate: item.evaluate || {},
      interview: item.interview || {},
      overall: item.overall || {},
    });
    setPdfDialog(true);
  };

  const closePdfDialog = () => {
    setPdfDialog(false);
    setSelectedItem(null);
  };

  const getStatus = (item) => {
    const { build, improve, evaluate, interview, overall } = item;
    const fields = [build, improve, evaluate, interview, overall];
    const filledFields = fields.filter(
      (field) => field !== null && field !== "" && typeof field === "object"
    );

    if (
      fields.every(
        (field) => field === null || field === "" || typeof field !== "object"
      )
    ) {
      return "Not Done";
    }

    if (filledFields.length === fields.length) {
      return "Completed";
    }

    return "Partial";
  };

  const getStatusColor = (item) => {
    const status = getStatus(item);
    switch (status) {
      case "Completed":
        return "green";
      case "Not Done":
        return "red";
      case "Partial":
        return "orange";
      default:
        return "grey";
    }
  };

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const headers = [
    { title: "User Id", key: "userID" },
    { title: "Email", key: "email" },
    { title: "Improve Trials", key: "improve_trials" },
    { title: "Evaluate Trials", key: "evaluate_trials" },
    { title: "Interview Trials", key: "interview_trials" },
    { title: "Status", key: "status" },
    { title: "Points", key: "points" },
    { title: "Details", key: "pdf" },
  ];

  return (
    <div>
      <strong className="my-6 px-6 text-center">Feedback Table</strong>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card className="p-3" variant="outlined">
              <TableContainer>
                <Table>
                  <TableHead className="text-gray-500 font-normal text-sm bg-gray-200">
                    <TableRow>
                      {headers.map((header) => (
                        <TableCell key={header.key}>{header.title}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {feedbackEntries.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.userID}</TableCell>
                        <TableCell>{item.email}</TableCell>
                        <TableCell>{item.improve_trials}</TableCell>
                        <TableCell>{item.evaluate_trials}</TableCell>
                        <TableCell>{item.interview_trials}</TableCell>
                        <TableCell>
                          <Chip
                            label={getStatus(item)}
                            style={{
                              backgroundColor: getStatusColor(item),
                              color: "white",
                            }}
                          />
                        </TableCell>
                        <TableCell>{item.points}</TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            color="warning"
                            onClick={() => openPdfDialog(item)}
                          >
                            View Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>

        <Dialog
          open={pdfDialog}
          onClose={closePdfDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Feedback Details</DialogTitle>
          <DialogContent>
            <Container>
              {selectedItem &&
                Object.entries(selectedItem).map(([sectionName, section]) => (
                  <Grid container key={sectionName}>
                    <Grid item xs={12}>
                      <Divider className="my-3" />
                      <h3 className="font-bold text-xl text-indigo-600">
                        {capitalize(sectionName)} Section
                      </h3>
                    </Grid>
                    {Object.entries(section).map(([questionKey, question]) => (
                      <Grid item xs={12} key={questionKey} className="mb-2">
                        <p className="font-bold mb-1 text-gray-700">
                          {question.text}
                        </p>
                        <p className="mb-2">
                          Answer:{" "}
                          {question.answer ? (
                            <Chip
                              label={question.answer}
                              style={{
                                backgroundColor: "green",
                                color: "white",
                              }}
                            />
                          ) : (
                            <Chip
                              label="No Answer"
                              style={{ backgroundColor: "red", color: "white" }}
                            />
                          )}
                        </p>
                      </Grid>
                    ))}
                  </Grid>
                ))}
            </Container>
          </DialogContent>
          <DialogActions>
            <Button onClick={closePdfDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
};

export default FeedbackTable;
