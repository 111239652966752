import http_1 from '../http_1'
import http from '../http_1'
import http_2 from '../http_2'

class dashboardData{

    edit_candidate_summary(data){
        return http.put("/edit_candidate_summary",data)
    }

    edit_other_details(data){
        return http.put("/edit_other_details",data)
    }

    edit_projects(data){
        return http.put("/edit_projects",data)
    }

    edit_qualification(data){
        return http.put("/edit_qualification",data)
    }

    edit_skills(data){
        return http.put("/edit_skills",data)
    }

    edit_experience(data){
        return http.put("/edit_experience",data)
    }

    edit_personal_details(data){
        return http.put("/edit_personal_details",data)
    }
}

export default new dashboardData();