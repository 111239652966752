import React, { useState, useRef } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Stepper,
  Step,
  StepLabel,
  Typography,
} from "@mui/material";
import { FaFilePdf, FaTimes } from "react-icons/fa";
import { TransitionGroup, CSSTransition } from "react-transition-group";

const GuideMe = () => {
  const [isActive, setIsActive] = useState(false);
  const [improve, setImprove] = useState(false);
  const [build, setBuild] = useState(false);
  const [evaluate, setEvaluate] = useState(false);
  const [response, setResponse] = useState(null);
  const activateGuideMeRef = useRef(null);

  const openGuide = () => {
    activateGuideMeRef.current.click();
  };

  const resetDialog = () => {
    setResponse(null);
    setIsActive(true);
    setImprove(false);
    setBuild(false);
    setEvaluate(false);
  };

  const handleResponse = (answer) => {
    setResponse(answer);
  };

  const startBuilding = () => {
    setIsActive(false);
    setBuild(true);
  };

  const startEvaluation = () => {
    setIsActive(false);
    setEvaluate(true);
  };

  const startImprovement = () => {
    setIsActive(false);
    setImprove(true);
  };

  const closeDialog = () => {
    setIsActive(false);
  };

  const closeBuildDialog = () => {
    setBuild(false);
  };

  const closeEvaluateDialog = () => {
    setEvaluate(false);
  };

  const closeImprovementDialog = () => {
    setImprove(false);
  };

  return (
    <div>
      {/* Main User Guide Dialog */}
      <Dialog open={isActive} onClose={closeDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          USER GUIDE
          <IconButton
            onClick={closeDialog}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <FaTimes />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TransitionGroup>
            {response === null && (
              <CSSTransition key="step1" timeout={500} classNames="fade">
                <div>
                  <img
                    src="/doyouhvcv.gif"
                    alt="Do you have a CV?"
                    className="image-transition"
                    
                  />
                  <Typography variant="h6">Do you have a CV?</Typography>
                  <div className="flex justify-center">
                    <Button
                      onClick={() => handleResponse(true)}
                      color="primary"
                    >
                      Yes👍
                    </Button>
                    <div className="mx-3"></div>
                    <Button
                      onClick={() => handleResponse(false)}
                      color="secondary"
                    >
                      No👎
                    </Button>
                  </div>
                </div>
              </CSSTransition>
            )}
            {response === true && (
              <CSSTransition key="step2" timeout={500} classNames="fade">
                <div>
                  <img src="/CV.png" alt="CV" className="image-transition" />
                  <Typography variant="h6">
                    Do you want to IMPROVE or EVALUATE your CV?
                  </Typography>
                  <div className="flex justify-center">
                    <Button onClick={startImprovement} color="primary">
                      IMPROVE
                    </Button>
                    <div className="mx-3"></div>
                    <Button onClick={startEvaluation} color="secondary">
                      EVALUATE
                    </Button>
                  </div>
                </div>
              </CSSTransition>
            )}
            {response === false && (
              <CSSTransition key="step3" timeout={500} classNames="fade">
                <div>
                  <img
                    src="/Step3.jpg"
                    alt="Step 3"
                    className="image-transition"
                  />
                  <Typography variant="h6">
                    Do you want to BUILD your own CV?
                  </Typography>
                  <div className="flex justify-center">
                    <Button onClick={startBuilding} color="primary">
                      Yes👍
                    </Button>
                    <div className="mx-3"></div>
                    <Button onClick={closeDialog} color="secondary">
                      No👎
                    </Button>
                  </div>
                </div>
              </CSSTransition>
            )}
          </TransitionGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Build Dialog */}
      <Dialog open={build} onClose={closeBuildDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          BUILD YOUR CV
          <IconButton
            onClick={closeBuildDialog}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <FaTimes />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={-1} orientation="vertical">
            <Step>
              <StepLabel>Build Your CV</StepLabel>
              <CardContent>
                <Typography>
                  Select ‘Build your CV’ to create your CV from scratch.
                </Typography>
              </CardContent>
            </Step>
            <Step>
              <StepLabel>Dashboard</StepLabel>
              <CardContent>
                <Typography>This will enable the Dashboard.</Typography>
              </CardContent>
            </Step>
            <Step>
              <StepLabel>Edit Sections</StepLabel>
              <CardContent>
                <Typography>
                  Click to edit individual sections of your CV from the
                  dashboard.
                </Typography>
              </CardContent>
            </Step>
            <Step>
              <StepLabel>Save Details</StepLabel>
              <CardContent>
                <Typography>Type and save your details.</Typography>
              </CardContent>
            </Step>
            <Step>
              <StepLabel>Download CV</StepLabel>
              <CardContent>
                <Typography>Download your CV from our platform.</Typography>
              </CardContent>
            </Step>
            <Step>
              <StepLabel>Choose Template</StepLabel>
              <CardContent>
                <Typography>Choose from three template options:</Typography>
                <ul>
                  <li>Two-column format</li>
                  <li>Single column format</li>
                  <li>Simple Doc</li>
                </ul>
              </CardContent>
            </Step>
            <Step>
              <StepLabel>Select Palette</StepLabel>
              <CardContent>
                <Typography>Select your desired color palette.</Typography>
              </CardContent>
            </Step>
          </Stepper>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeBuildDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Improvement Dialog */}
      <Dialog
        open={improve}
        onClose={closeImprovementDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          IMPROVE YOUR CV
          <IconButton
            onClick={closeImprovementDialog}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <FaTimes />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={-1} orientation="vertical">
            <Step>
              <StepLabel>Select Improve Option</StepLabel>
              <CardContent>
                <Typography>
                  Select the ‘Improve My CV’ option for a quick enhancement
                  without in-depth evaluation.
                </Typography>
              </CardContent>
            </Step>
            <Step>
              <StepLabel>AI Improvement Methods</StepLabel>
              <CardContent>
                <Typography>
                  Our AI will improve your CV in three ways:
                </Typography>
                <ol>
                  <li>
                    <strong>Based on its own merit</strong>
                  </li>
                  <li>
                    <strong>Based on job role</strong>
                  </li>
                  <li>
                    <strong>Based on job role and description</strong>
                  </li>
                </ol>
              </CardContent>
            </Step>
            <Step>
              <StepLabel>View Improvement Result</StepLabel>
              <CardContent>
                <Typography>
                  You will be directed to the improvement result page to view
                  and download your improvement report.
                </Typography>
              </CardContent>
            </Step>
            <Step>
              <StepLabel>Download Improved CV</StepLabel>
              <CardContent>
                <Typography>Navigate back to the dashboard to:</Typography>
                <Typography>
                  Download the improved CV using one of the templates.
                </Typography>
              </CardContent>
            </Step>
          </Stepper>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeImprovementDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Evaluate Dialog */}
      <Dialog
        open={evaluate}
        onClose={closeEvaluateDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          EVALUATE YOUR CV
          <IconButton
            onClick={closeEvaluateDialog}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <FaTimes />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stepper activeStep={-1} orientation="vertical">
            <Step>
              <StepLabel>Select Evaluation Option</StepLabel>
              <CardContent>
                <Typography>
                  Select the ‘Evaluate Your CV’ option for an in-depth
                  evaluation and detailed report.
                </Typography>
              </CardContent>
            </Step>
            <Step>
              <StepLabel>AI Evaluation Methods</StepLabel>
              <CardContent>
                <Typography>
                  Our AI will evaluate your CV in three ways:
                </Typography>
                <ol>
                  <li>
                    <strong>Based on its own merit</strong>
                  </li>
                  <li>
                    <strong>Based on job role</strong>
                  </li>
                  <li>
                    <strong>Based on job role and description</strong>
                  </li>
                </ol>
              </CardContent>
            </Step>
            <Step>
              <StepLabel>User Categories</StepLabel>
              <CardContent>
                <Typography>
                  There are two categories of users for CV evaluation:
                </Typography>
                <ul>
                  <li>
                    <strong>Category 1: With non-improved CVs</strong>
                    <ul>
                      <li>
                        First, make quick improvements using the ‘Improve’
                        button to become a Category 2 user.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>Category 2: With already improved CVs</strong>
                    <ul>
                      <li>Study and download the evaluation report.</li>
                      <li>
                        Use the dashboard to add any suggestions from the report
                        to your CV.
                      </li>
                      <li>Re-evaluate to further refine your CV.</li>
                      <li>Download the final CV</li>
                    </ul>
                  </li>
                </ul>
              </CardContent>
            </Step>
            <Step>
              <StepLabel>Re-evaluation Process</StepLabel>
              <CardContent>
                <Typography>
                  After making any necessary adjustments based on the evaluation
                  report, you can re-evaluate your CV to see further
                  improvements.
                </Typography>
              </CardContent>
            </Step>
          </Stepper>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEvaluateDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Activator Button */}
      <button
        onClick={resetDialog}
        ref={activateGuideMeRef}
        className="whitespace-nowrap hover:bg-primary hover:text-white transition duration-300 px-3 py-2 rounded-lg"
      >
        Guide Me
      </button>
    </div>
  );
};

export default GuideMe;
