import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
  Grid,
  Dialog,
  DialogContent,
} from "@mui/material";
import { MdCheckCircle } from "react-icons/md";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { closeFeedback } from "../../GlobalRedux/Features/GlobalStateSlice";
import userData from "../../services/userData";

const ImproveFeedback = () => {
  const [improveFeedbackSubmitted, setImproveFeedbackSubmitted] =
    useState(false);
  const [feedback, setFeedback] = useState({ q1: null, q2: null, q3: "" });
  const colors = ["red", "orange", "yellow", "green", "blue"]; // Color scheme for radio buttons

  useEffect(() => {
    // Check if feedback was submitted earlier
    setImproveFeedbackSubmitted(false);
  }, []);

  const isSaveEnabled = feedback.q1 !== null && feedback.q2 !== null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [name]: value,
    }));
  };

  const saveFeedback = async () => {
    setImproveFeedbackSubmitted(true);
    localStorage.setItem("improveFeedbackSubmitted", JSON.stringify(true)); // Store this state in local storage
    const points = (feedback.q1 || 0) + (feedback.q2 || 0);
    const token = Cookies.get("token");
    const improveFeedbackData = {
      email: "sample@example.com", // Replace with actual email if dynamic
      improve: {
        question1: {
          text: "How would you rate the improvement of my CV section?",
          answer: feedback.q1,
        },
        question2: {
          text: "Did it address and clear your concerns about your CV?",
          answer: feedback.q2,
        },
        question3: {
          text: "Did you find any flaws in this Improve CV section and are there any suggestions to improve this section?",
          answer: feedback.q3,
        },
        question4: {
          text: "Are there any other suggestions you would like to add for this Improve CV section?",
          answer: feedback.q4,
        },
      },
      points: points,
      token,
    };

    try {
      const res = await userData.storeFeedback("improve", improveFeedbackData);
      console.log("Data submitted successfully:", res.data);
      setTimeout(() => {
        dispatch(closeFeedback("improve"));
      }, 1000);
    } catch (error) {
      console.error("Error storing feedback:", error);
    }
  };

  const dispatch = useDispatch();
  const improve = useSelector((state) => state.GlobalState.improve);

  return (
    <Dialog open={Boolean(improve)} onClose={() => dispatch(closeFeedback("improve"))}>
      <DialogContent>
        {!improveFeedbackSubmitted ? (
          <Card elevation={0}>
            <CardHeader title="Improve CV" />
            <CardContent>
              <Grid container spacing={2}>
                {/* Question 1 */}
                <Grid item xs={12}>
                  <Typography>
                    How would you rate the improvement of my CV section?
                  </Typography>
                  <RadioGroup
                    name="q1"
                    value={feedback.q1}
                    onChange={handleChange}
                    row
                  >
                    {[...Array(5)].map((_, index) => (
                      <FormControlLabel
                        key={index}
                        value={index}
                        control={<Radio style={{ color: colors[index] }} />}
                        label={index.toString()}
                      />
                    ))}
                  </RadioGroup>
                </Grid>

                {/* Question 2 */}
                <Grid item xs={12}>
                  <Typography>
                    Did it address and clear your concerns about your CV?
                  </Typography>
                  <RadioGroup
                    name="q2"
                    value={feedback.q2}
                    onChange={handleChange}
                    row
                  >
                    {[...Array(5)].map((_, index) => (
                      <FormControlLabel
                        key={index}
                        value={index}
                        control={<Radio style={{ color: colors[index] }} />}
                        label={index.toString()}
                      />
                    ))}
                  </RadioGroup>
                </Grid>

                {/* Optional Feedback */}
                <Grid item xs={12}>
                  <Typography>
                    Did you find any flaws in this{" "}
                    <span className="font-semibold">Improve CV</span> section and are there any
                    suggestions to improve this section?
                  </Typography>
                  <TextField
                    name="q3"
                    value={feedback.q3}
                    onChange={handleChange}
                    multiline
                    rows={2}
                    variant="outlined"
                    fullWidth
                    InputProps={{ notched: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Are there any other suggestions you would like to add for
                    this <span className="font-semibold">Improve CV</span> section?
                  </Typography>
                  <TextField
                    name="q4"
                    value={feedback.q4}
                    onChange={handleChange}
                    multiline
                    rows={2}
                    variant="outlined"
                    fullWidth
                    InputProps={{ notched: true }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <button
                onClick={saveFeedback}
                variant="contained"
                disabled={!isSaveEnabled}
                className="primary-button w-full"
              >
                Save
              </button>
            </CardActions>
          </Card>
        ) : (
          <Card className="feedback-submitted-card">
            <CardHeader
              avatar={<MdCheckCircle style={{ color: "#4caf50" }} />}
              title="Feedback Submitted"
              titleTypographyProps={{ variant: "h6" }}
            />
            <CardContent>
              <Typography>
                Thank you for your feedback! It has been successfully submitted.
              </Typography>
            </CardContent>
          </Card>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ImproveFeedback;
