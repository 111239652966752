import React, { useState, useEffect, useRef } from "react";
import { FaMicrophone } from "react-icons/fa6";
import Cookies from "js-cookie";
import userData from "../services/userData";
import Markdown from "react-markdown";
import InterviewResults from "./InterviewResults";
import { Audio } from "react-loader-spinner";
import { RiDeleteBin6Line } from "react-icons/ri";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { MdOutlineFileDownload } from "react-icons/md";
import { index } from "d3";

const obj = [
  {
    question: "Introduce yourself",
    results: "",
    recording: false,
    loading: false,
  },
  {
    question: "What are your strengths and weaknesses?",
    results: "",
    recording: false,
    loading: false,
  },
  {
    question: "Why do you want to join the organisation?",
    results: "",
    recording: false,
    loading: false,
  },
];

export function Recording({ timer }) {
  return (
    <div className="flex gap-3 items-center justify-center">
      <Audio height={40} width={40} color="blue" />
      <p>{timer + " s left"}</p>
    </div>
  );
}

export function Loading() {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </Box>
  );
}

function InterviewRoom() {
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const handleQuestionSelect = (event) => {
    setSelectedQuestion(event.target.value);
  };
  const [timer, setTimer] = useState(5);
  const [processingResults, setProcessingResults] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedAudioUrl, setRecordedAudioUrl] = useState("");
  const [recording, setRecording] = useState(false);
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [error, setError] = useState({});
  const [trialsRemaining, setTrialsRemaining] = useState("");
  const [showFeedback, setShowFeedback] = useState(0);
  const [showForm, setShowForm] = useState(0);
  const countdownRef = useRef(null);

  const [selectedResults, setSelectedResults] = useState(null);
  const [questionArray, setQuestionArray] = useState(obj);
  const [newQuestion, setNewQuestion] = useState("");
  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    checkFeedback();
  }, []);

  const checkFeedback = async () => {
    const token = Cookies.get("token");
    const data = {
      token,
      service: "interview",
    };
    try {
      const res = await userData.checkFeedbackStatus(data);
      if (res.data?.success === true) {
        setTrialsRemaining(res.data.trials);
      }
      if (res.data.trials === 0 && !res.data.feedback) {
        setShowForm(1);
      }
    } catch (err) {
      console.error("Error while checking feedback status:", err);
    }
  };

  const getTrialsRemaining = async () => {
    const token = Cookies.get("token");
    try {
      const res = await userData.getTrialsRemaining(token);
      setTrialsRemaining(res.data?.trialsremaining);
    } catch (err) {
      console.log(err);
    }
  };

  const startRecording = async (selectedQuestion) => {
    if (!selectedQuestion) {
      alert("Please select a question first.");
      return;
    }
    setRecording(true);
    setTimer(30);
    let audioChunks = [];

    return new Promise(async (resolve, reject) => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const recorder = new MediaRecorder(stream);
        setMediaRecorder(recorder);

        recorder.ondataavailable = (event) => {
          audioChunks.push(event.data);
        };

        recorder.onstop = () => {
          const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
          resolve(audioBlob);
        };

        recorder.start();

        countdownRef.current = setInterval(() => {
          setTimer((prevTimer) => {
            if (prevTimer === 1) {
              clearInterval(countdownRef.current);
              recorder.stop();
              setRecording(false);
              return 0;
            }
            return prevTimer - 1;
          });
        }, 1000);
      } catch (error) {
        console.error("Error accessing audio device:", error);
        reject(error);
      }
    });
  };

  const sendRecording = async (question, audioBlob) => {
    console.log(question, audioBlob);
    try {
      const token = Cookies.get("token");
      let formData = new FormData();
      const getIdres = await userData.getId(token);
      const id = getIdres.data.id;
      formData.append("question", question);
      formData.append("audio", audioBlob, "interview.wav");
      formData.append("user_id", id);
      let response = await userData.evaluateInterview(formData);
      console.log("Evaluation report: ", response);
      return response.data;
    } catch (err) {
      console.error("Error sending recording:", error);
      throw new Error("Error while processing results");
    }
  };

  async function handleRecording(index) {
    try {
      setQuestionArray((prev) => {
        const updated = [...prev];
        updated[index].recording = true;
        return updated;
      });

      setError({});

      const audioBlob = await startRecording(questionArray[index]["question"]);

      setQuestionArray((prev) => {
        const updated = [...prev];
        updated[index].recording = false;
        updated[index].loading = true;
        return updated;
      });

      const results = await sendRecording(
        questionArray[index]["question"],
        audioBlob
      );

      localStorage.setItem("temp", JSON.stringify(results));

      setQuestionArray((prev) => {
        const updated = [...prev];
        updated[index].loading = false;
        updated[index].results = results;
        return updated;
      });

      const tempData = [];
      for (let question in questionArray) {
        if (question.results) {
          tempData.push(question);
        }
      }

      localStorage.setItem("interview-results", JSON.stringify(tempData));
    } catch (err) {
      console.log(err);
      setQuestionArray((prev) => {
        const updated = [...prev];
        updated[index].loading = false;
        updated[index].loading = false;
        return updated;
      });

      setError((prev) => {
        return {
          ...prev,
          [index]: "Could not understand audio. Please try again.",
        };
      });
      console.log("Hello guys: ", error);
    }
  }

  const handleAddQuestion = (e) => {
    e.preventDefault();
    if (newQuestion.trim() === "") return;
    setQuestionArray([
      ...questionArray,
      { question: newQuestion, response: "", recording: false, loading: false },
    ]);
    setNewQuestion("");
  };

  const deleteQuestion = (index) => {
    setQuestionArray((prev) => prev.filter((_, i) => i !== index));
  };

  const downloadHandler = async () => {
    console.log("Download called");
    try {
      const data = questionArray.filter((q) => q.results);
      if (!data || data.length === 0) {
        setError((prev) => {
          return {
            ...prev,
            gl: "You do not have any results to download!",
          };
        });
        return;
      }
      setDownloading(true);
      const response = await userData.downloadInterviewReport({
        questionArray: data,
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "interview_report.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      console.error("Error while downloading report", err);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <div className=" p-4">
      <InterviewResults
        results={selectedResults}
        setResults={setSelectedResults}
      />
      <div className="w-full ">
        <table className=" w-[680px] md:w-full bg-white border border-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 border-b text-center">Questions</th>
              <th className="py-2 px-4 border-b text-center">Actions</th>
              <th className="py-2 px-4 border-b text-center">Results</th>
            </tr>
          </thead>
          <tbody>
            {questionArray.map((q, i) => (
              <tr key={i} className="hover:bg-gray-50">
                <td className="py-2 px-4 border-b">{q.question}</td>
                <td className="py-2 px-4 border-b text-center align-middle">
                  {q.recording && <Recording timer={timer} />}
                  {q.loading && <Loading />}
                  {!q.recording && !q.loading && !q.results && (
                    <button
                      onClick={() => handleRecording(i)}
                      disabled={recording}
                      className="secondary-button bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600 min-w-[100px]"
                    >
                      Record
                    </button>
                  )}
                  {!q.recording && !q.loading && q.results && (
                    <div className="flex gap-4 items-center justify-center">
                      <button
                        onClick={() => handleRecording(i)}
                        className="secondary-button bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600 min-w-[100px]"
                      >
                        Retry
                      </button>
                      <div className="p-2 rounded-lg">
                        <button
                          className="secondary-button"
                          onClick={(e) => {
                            e.preventDefault();
                            setQuestionArray((prev) => {
                              return prev.filter((_, it) => it !== i);
                            });
                          }}
                        >
                          <RiDeleteBin6Line size={25} />
                        </button>
                      </div>
                    </div>
                  )}
                  {error[i] && (
                    <p className="text-xs font-[550] mt-1 text-red-500">
                      {error[i]}
                    </p>
                  )}
                </td>
                <td className="py-2 px-4 border-b text-center align-middle">
                  {q.results && !q.loading ? (
                    <button
                      className="primary-button bg-green-500 text-white py-1 px-3 rounded hover:bg-green-600"
                      onClick={() => setSelectedResults(q.results)}
                    >
                      Show results
                    </button>
                  ) : q.results && q.loading ? (
                    <p className="text-sm bg-primary/10 px-3 py-1 rounded-xl">
                      Processing...
                    </p>
                  ) : (
                    "NA"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-between items-center mt-5">
        <form
          onSubmit={handleAddQuestion}
          className="mt-4 flex items-center justify-start"
        >
          <input
            onClick={handleAddQuestion}
            className="primary-button text-white py-2 px-4 rounded "
            value={"Add Question"}
            type="submit"
          />

          <input
            type="text"
            value={newQuestion}
            onChange={(e) => setNewQuestion(e.target.value)}
            className="border border-gray-300 p-2 rounded ml-2"
            placeholder="Enter new question"
            required
          />
        </form>

        <div className="">
          <button
            className="secondary-button flex gap-1 items-center"
            onClick={downloadHandler}
            disabled={downloading}
          >
            Download report
            <MdOutlineFileDownload size={20} />
          </button>
        </div>
      </div>

      {error.gl && (
        <p className="text-red-500 font-semibold text-sm text-center mt-3"><span className="font-bold">Error:{' '}</span>{error.gl}</p>
      )}
    </div>
  );
}

export default InterviewRoom;
