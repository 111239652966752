import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CircularProgress,
  Container,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import AdminPortalGrid from "./AdminPortalGrid";
import AllUsersGrid from "./AllUsersGrid";
import AdminDashboard from "./AdminDashboard";
import userData from "../../services/userData";
import GuideMe from "../GuideMe";

const AdminPortal = () => {
  const [loading, setLoading] = useState(false);
  const [pendingUsers, setPendingUsers] = useState(null);
  const [activeUsers, setActiveUsers] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyAdmin = async () => {
      try {
        const token = Cookies.get("token");
        const res = await userData.verifyAdmin(token);
        if (res.data.success !== true) {
          navigate("/");
        }
      } catch (err) {
        console.log(err);
        navigate("/");
      }
    };

    verifyAdmin();
    fetchUsers();
  }, []);

  const selectVersion = async (version) => {
    try {
      const res = await userData.selectVersion({
        version,
        email: "adityakumar.tech24@gmail.com",
      });
      if (res.data?.success) {
        console.log("Successfully selected version: ", version);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      await fetchPendingUsers();
      await fetchAllUsers();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchPendingUsers = async () => {
    try {
      const res = await userData.getPendingUsers();
      if (res.data.users) {
        setPendingUsers(res.data.users);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const grantAccess = async (email) => {
    setLoading(true);
    try {
      const res = await userData.grantAccess(email);
      if (res.data.success) {
        fetchUsers();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllUsers = async () => {
    try {
      const res = await userData.getAllUsers();
      if (res.data.users) {
        setActiveUsers(res.data.users);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [bulk , setBulk] = useState('')

  const bulkAccess = async () => {
    try{
      const token = Cookies.get('token');
      const res = await userData.grantBulk({
        bulk,
        token,
      })
      setBulk('');
      alert('Users granted access successfully');
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
      <p variant="h3" className="admin-heading text-center text-2xl font-bold w-full">
        Admin Portal
      </p>

      <strong variant="" className="mt-24 text-center">
        Select Prompt Version
      </strong>

      <Card className="flex justify-center gap-8 p-8 m-8">
        <Button variant="contained" onClick={() => selectVersion(1)}>
          Version 1
        </Button>
        <Button variant="contained" onClick={() => selectVersion(2)}>
          Version 2
        </Button>
      </Card>

      <strong variant="" className="mt-24 text-center w-full ">
        Pending Users
      </strong>
      <Container className="container">
        <AdminPortalGrid
          rows={pendingUsers}
          loading={loading}
          grantAccess={grantAccess}
        />
      </Container>

      <Container>
        <AdminDashboard />
      </Container>

      <div className="flex flex-col gap-5 items-center mt-12">
  <div>
  <p className="font-bold text-lg text-center">Bulk access granting portal</p>
  <p className="text-sm text-secondary-text">Put all emails space separated</p>
  </div>
        <TextareaAutosize className="bg-primary/10 min-w-[300px] p-5 w-full" minRows={5}  value={bulk} onChange={(e) => {
          console.log(e.target.value);
          setBulk(e.target.value)
        }} />
        <button className="primary-button" onClick={bulkAccess}>Grant</button>
      </div>
    </div>
  );
};

export default AdminPortal;
