import axios from "axios";

export default axios.create({
  baseURL: "https://parinaam-node.yuktii.com/",
  // baseURL: "https://yantra-backend-1.azurewebsites.net",
  headers: {
    "Content-type": "application/json",
  },
});

export const baseURL1 = 'https://parinaam-node.yuktii.com'
