import React from "react";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className="relative">
      <div className="absolute top-[48px] left-[48px] flex gap-6 items-center">
        <img src="/logo.png" height={50} width={50} />
        <div className="flex flex-col items-center justify-between custom-family translate-y-2">
          <div className=" font-bold text-[#0070C0] flex text-4xl">
            <p>Y</p>
            <p className=" text-[#FF671F]">U</p>
            <p>K</p>
            <p>T</p>
            <p>I</p>
            <p>I</p>
          </div>
          <div className="text-gray-600 font-bold">VENTURES</div>
        </div>
      </div>

      <div className="grid grid-cols-3 md:grid-cols-2 px-12 mt-7 gap-7">
        <div className="flex flex-col gap-5 mt-32">
          <div className="">
            <h1 className="text-4xl font-bold">Welcome to PARINAAM</h1>
          </div>
          <div>
            <p className="text-secondary-text">
              Our application offers personalized career guidance to empower
              aspirants in making informed decisions, exploring opportunities,
              and achieving their personal and professional goals.
            </p>
          </div>
          <div className="flex gap-2">
            <Link to="/login" className="secondary-button">
              Login
            </Link>
            <Link to="/register" className="primary-button">
              Sign Up
            </Link>
          </div>

          <p className="text-secondary-text mt-7 font-semibold">
            {
              "Parinaam: In Sanskrit the word परिणाम (Pariṇām) means transformation, change, results, and outcome. For those who are looking for a transformative career journey, PARINAAM will be your lifelong companion!"
            }
          </p>
        </div>
        <div className="rounded-xl overflow-hidden">
          <img src="/reached-top.jpg" className="hidden md:block" />
        </div>
      </div>
    </div>
  );
}

export default Home;
