import React, { useEffect, useState } from "react";
import userData from "../services/userData";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";

import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { countries } from "../assets/countryData";
import PhoneInputComponent from "./PhoneInputComponent";

function EditProfile() {
  const navigate = useNavigate();

  const [message, setMessage] = useState("");

  const [timeIntern, setTimeIntern] = useState("");
  const [timeFull, setTimeFull] = useState("");

  const [initialData, setInitialData] = useState({
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    street: "",
    postalCode: "",
    city: "",
    state: "",
    country: "",
    showDatePicker: false,
    dob: null,
    contactNumber: "",
    Intern_years: "",
    Intern_months: "",
    FullTime_years: "",
    FullTime_months: "",
    showTooltip_1: false,
    showTooltip_2: false,
    role: "",
    role_level: "",
    role_name: "",
    src: "",
  });

  const [allow, setAllow] = useState(false);

  const [edit, setEdit] = useState({
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    street: "",
    postalCode: "",
    city: "",
    state: "",
    country: "",
    showDatePicker: false,
    dob: "",
    contactNumber: "",
    Intern_years: "",
    Intern_months: "",
    FullTime_years: "",
    FullTime_months: "",
    showTooltip_1: false,
    showTooltip_2: false,
    role: "",
    role_level: "",
    role_name: "",
    src: "",
  });

  const [error, setError] = useState("");

  const getData = async () => {
    try {
      const token = Cookies.get("token");
      const res = await userData.getProfileData(token);
      console.log("Pr data = ", res.data);
      setError("");
      const profileData = res.data;

      const updatedEdit = {
        title: profileData.title,
        firstName: profileData.firstName,
        middleName: profileData.middleName,
        lastName: profileData.lastName,
        street: profileData.main_address,
        city: profileData.city,
        postalCode: profileData.postal_code,
        state: profileData.state,
        country: profileData.country,
        dob: formatDate(profileData.dob),
        contactNumber: profileData.contactNumber,
        role_level: profileData.role_level,
        role_name: profileData.role_name,
        role:
          profileData.role_level !== "" && profileData.role_level !== null
            ? "1"
            : "2",
      };

      console.log("Updated Edit: ", updatedEdit);

      setEdit(updatedEdit);
      setTimeIntern(profileData.parttime_experience);
      setTimeFull(profileData.fulltime_experience);
      splitDuration(
        profileData.parttime_experience,
        profileData.fulltime_experience
      );

      const updatedInitialData = { ...updatedEdit };
      setInitialData(updatedInitialData);
      console.log(initialData);
      splitDuration(
        profileData.parttime_experience,
        profileData.fulltime_experience
      );
      splitDuration(
        profileData.parttime_experience,
        profileData.fulltime_experience
      );
    } catch (err) {
      console.log(err);
      setError("An error occurred while fetching data");
    }
  };

  const splitDuration = (timeIntern, timeFull) => {
    console.log("Splitting duration");
    const regex = /(\d+)\s+years?\s+(\d+)\s+months?/i;

    const matches_1 = timeIntern.match(regex);
    if (matches_1 && matches_1.length === 3) {
      setEdit((prevEdit) => ({
        ...prevEdit,
        Intern_years: parseInt(matches_1[1], 10),
        Intern_months: parseInt(matches_1[2], 10),
      }));
      setInitialData((prevInitialData) => ({
        ...prevInitialData,
        Intern_years: parseInt(matches_1[1], 10),
        Intern_months: parseInt(matches_1[2], 10),
      }));
    }

    const matches_2 = timeFull.match(regex);
    if (matches_2 && matches_2.length === 3) {
      setEdit((prevEdit) => ({
        ...prevEdit,
        FullTime_years: parseInt(matches_2[1], 10),
        FullTime_months: parseInt(matches_2[2], 10),
      }));
      setInitialData((prevInitialData) => ({
        ...prevInitialData,
        FullTime_years: parseInt(matches_2[1], 10),
        FullTime_months: parseInt(matches_2[2], 10),
      }));
    }
    console.log("Printing results...");
    console.log(edit);
    console.log(initialData);
  };

  const format = (dob) => {
    const day = dob.getDate();
    const month = dob.getMonth() + 1;
    const year = dob.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const formatExperience = (years, months) => {
    let experienceString = "";

    if (years !== "") {
      experienceString += years + " year";
      if (parseInt(years) !== 1) {
        experienceString += "s"; // pluralize "year" if necessary
      }
    }

    if (months !== "") {
      if (experienceString !== "") {
        experienceString += " ";
      }
      experienceString += months + " month";
      if (parseInt(months) !== 1) {
        experienceString += "s"; // pluralize "month" if necessary
      }
    }

    if (experienceString === "") {
      experienceString = "0 months"; // default to 0 months if no experience provided
    }

    return experienceString;
  };

  const saveProfile = async () => {
    const token = Cookies.get("token");
    if (edit.role === "1" && edit.role_name === "" && edit.role_level === "") {
      setError(
        "If you have a specific role in mind, fill the required details"
      );
    } else {
      try {
        setError("");
        const partTimeExperience = formatExperience(
          edit.Intern_years,
          edit.Intern_months
        );
        const fullTimeExperience = formatExperience(
          edit.FullTime_years,
          edit.FullTime_months
        );

        if (edit.role === "2") {
          setEdit((prevEdit) => ({
            ...prevEdit,
            role_name: "",
            role_level: "",
          }));
        }

        const data = {
          title: edit.title,
          firstName: edit.firstName,
          lastName: edit.lastName,
          middleName: edit.middleName,
          street: edit.street,
          city: edit.city,
          state: edit.state,
          country: edit.country,
          postalcode: edit.postalCode,
          dob: edit.dob,
          number: edit.contactNumber,
          partTime: partTimeExperience,
          fullTime: fullTimeExperience,
          role_name: edit.role_name,
          role_level: edit.role_level,
          token: token,
        };

        console.log("Saving data = ", data);

        const res = await userData.editProfile(data);
        if (res) {
          setMessage("Changes saved successfully");
          setTimeout(() => {
            navigate(-1);
          }, 800);
        }
      } catch (err) {
        console.log(err);
        setError(err.response?.data?.message || "Error while saving profile");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEdit((prevEdit) => ({
      ...prevEdit,
      [name]: value,
    }));
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <p className="text-xl font-bold text-primary-bold text-center">
        Profile details
      </p>
      <p className="text-center text-secondary-text mt-2">
        {!allow && <span className="mr-2">Edit information?</span>}
        {allow && (
          <p className="text-green-500 font-bold text-sm">
            Form fields are now editable!
          </p>
        )}
        {!allow && (
          <button
            onClick={() => setAllow(true)}
            className="secondary-button py-1"
          >
            Edit
          </button>
        )}
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          saveProfile();
        }}
        className="form-container flex flex-col gap-8 mt-5 p-5"
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Title</InputLabel>
              <Select
                value={edit.title}
                onChange={handleChange}
                label="Title"
                name="title"
                disabled={!allow}
              >
                <MenuItem value="Mr.">Mr.</MenuItem>
                <MenuItem value="Ms.">Ms.</MenuItem>
                <MenuItem value="Dr.">Dr.</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              label="First Name"
              variant="outlined"
              value={edit.firstName}
              onChange={handleChange}
              name="firstName"
              disabled={!allow}
              inputProps={{ maxLength: 500 }}
              required
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Middle Name"
              variant="outlined"
              value={edit.middleName}
              onChange={handleChange}
              name="middleName"
              disabled={!allow}
              inputProps={{ maxLength: 500 }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Last Name"
              variant="outlined"
              value={edit.lastName}
              onChange={handleChange}
              name="lastName"
              disabled={!allow}
              inputProps={{ maxLength: 500 }}
              required
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Main Address"
              variant="outlined"
              value={edit.street}
              onChange={handleChange}
              name="street"
              disabled={!allow}
              inputProps={{ maxLength: 500 }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Postal Code"
              variant="outlined"
              type="number"
              value={edit.postalCode}
              onChange={handleChange}
              name="postalCode"
              disabled={!allow}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="City"
              variant="outlined"
              value={edit.city}
              onChange={handleChange}
              name="city"
              disabled={!allow}
              inputProps={{ maxLength: 500 }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="State"
              variant="outlined"
              value={edit.state}
              onChange={handleChange}
              name="state"
              disabled={!allow}
              inputProps={{ maxLength: 500 }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              fullWidth
              options={countries}
              getOptionLabel={(option) => option}
              value={edit.country}
              onChange={(event, newValue) => {
                handleChange({
                  target: {
                    name: "country",
                    value: newValue,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country"
                  variant="outlined"
                  disabled={!allow}
                  required
                />
              )}
              onSubmit={(e) => {
                e.preventDefault();

                if (countries && countries.length > 0) {
                  // Grab the first suggestion from options
                  const firstSuggestion = countries[0];

                  handleChange({
                    target: {
                      name: "country",
                      value: firstSuggestion,
                    },
                  });
                }
                // If there are no options, do nothing
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="flex gap-2 items-center">
              <label htmlFor="dob">DOB:</label>
              <input
                id="dob"
                type="date"
                value={edit.dob}
                name="dob"
                disabled={!allow}
                required
                onChange={handleChange}
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />{" "}
            </div>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Contact number"
                variant="outlined"
                value={edit.contactNumber}
                onChange={handleChange}
                className="w-full"
                name="contactNumber"
                disabled={!allow}
              />
            </Grid>{" "}
          </Grid>
        </Grid>

        <Grid container spacing={8} className="mt-12">
          <Grid item xs={12}>
            <Typography variant="h6">Present experience</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6">Part time</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="years"
              variant="outlined"
              type="number"
              value={edit.Intern_years}
              onChange={handleChange}
              name="Intern_years"
              disabled={!allow}
              required
              inputProps={{ min: 0, max: 100 }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="months"
              variant="outlined"
              type="number"
              value={edit.Intern_months}
              onChange={handleChange}
              name="Intern_months"
              disabled={!allow}
              required
              inputProps={{ min: 0, max: 11 }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h6">Full time</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="years"
              variant="outlined"
              type="number"
              value={edit.FullTime_years}
              onChange={handleChange}
              name="FullTime_years"
              disabled={!allow}
              required
              inputProps={{ min: 0, max: 100 }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="months"
              variant="outlined"
              type="number"
              value={edit.FullTime_months}
              onChange={handleChange}
              name="FullTime_months"
              disabled={!allow}
              required
              inputProps={{ min: 0, max: 11 }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">
              Do you have a specific role in mind
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RadioGroup
              row
              value={edit.role}
              onChange={handleChange}
              disabled={!allow}
              name="role"
            >
              <FormControlLabel value="1" control={<Radio />} label="Yes" />
              <FormControlLabel value="2" control={<Radio />} label="No" />
            </RadioGroup>
          </Grid>
        </Grid>

        {edit.role === "1" && (
          <Grid container spacing={2}>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Role"
                variant="outlined"
                value={edit.role_name}
                onChange={handleChange}
                name="role_name"
                disabled={!allow}
                inputProps={{ maxLength: 500 }}
              />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Role level</InputLabel>
                <Select
                  value={edit.role_level}
                  onChange={handleChange}
                  label="Role level"
                  name="role_level"
                  disabled={!allow}
                >
                  <MenuItem value="entry level (0-3 years)">
                    entry level (0-3 years)
                  </MenuItem>
                  <MenuItem value="mid-senior level (3-10 years)">
                    mid-senior level (3-10 years)
                  </MenuItem>
                  <MenuItem value="senior level (>10 years)">
                    senior level {"(>10 years)"}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
        {allow && (
          <input
            type="submit"
            value="Save"
            className="my-8 w-full primary-button"
          />
        )}
      </form>
    </div>
  );
}

export default EditProfile;
