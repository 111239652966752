import React, { useEffect, useRef, useState } from "react";
import { HiOutlineUserCircle } from "react-icons/hi2";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setDynamicId } from "../GlobalRedux/Features/GlobalStateSlice";
import userData from "../services/userData";
import { NavLink } from "react-router-dom";
import GuideMe from "./GuideMe";

function NavText({ text }) {
  return (
    <p className=" custom-shadow rounded-full px-12 py-2 font-semibold ">
      {text}
    </p>
  );
}

function Navbar() {

  const ref = useRef();
  const guideMeRef = useRef();

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const [username , setUsername] = useState('Guest user');

  const deleteData = [
    "ai_improvements",
    "gaps",
    "interview-results",
    "matrix_score",
    "overall_score",
    "suggestions",
    "temp",
  ];

  const logoutHandler = () => {
    setOpen(false);
    deleteData.forEach((data) => {
      localStorage.removeItem(data);
    });
    Cookies.remove("token");
    navigate("/");
  };

  const location = useLocation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        guideMeRef.current &&
        !guideMeRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    async function getDynamic() {
      try {
        const token = Cookies.get("token");
        if (token) {
          const lastDataResponse = await userData.checkLastData(token);
          let id = lastDataResponse.data.id || 0;
          if(!id){
            const field = await userData.getNewField(token);
            id = field.data?.id;
          }
          const tempName = lastDataResponse?.data?.name || 'Guest user';
          setUsername(tempName)
          dispatch(setDynamicId(id));
        } else navigate("/");
      } catch (err) {
        console.log(err);
        navigate("/");
      }
    }
    if (location.pathname !== "/change-password") getDynamic();

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [location.pathname]);

  const userManual = () => {
    window.open("/user_manual.pdf", "_blank");
  };

  const dynamic_id = useSelector((state) => state.GlobalState.dynamic_id);

  if (location.pathname === "/change-password" || location.pathname === "/profile") {
    return null;
  }

  return (
    <div className="flex justify-between items-center px-2 py-2">
      <p className=" custom-shadow rounded-full px-12 py-2 font-semibold ">
        Hi, {username}{'!'}
      </p>
      <div
        ref={ref}
        className="rounded-lg p-2 hover:bg-slate-200 cursor-pointer relative"
        onClick={() => setOpen(true)}
      >
        <HiOutlineUserCircle size={40} />
        {open && (
          <div
            onMouseLeave={() => setOpen(false)}
            className="z-10 glassy-bg flex flex-col gap-3 font-semibold absolute right-0 top-12 rounded-lg  px-8 py-3"
          >
            <NavLink
              to={`/user-home/${dynamic_id}`}
              className="whitespace-nowrap hover:bg-primary hover:text-white transition duration-300 px-3 py-2 rounded-lg"
            >
              Home
            </NavLink>

            <NavLink
              to="/edit-profile"
              className="whitespace-nowrap hover:bg-primary hover:text-white transition duration-300 px-3 py-2 rounded-lg"
            >
              Profile
            </NavLink>
            <div ref={guideMeRef} onClick={(e) => e.stopPropagation()}>
              <GuideMe />
            </div>
            <div
              className="whitespace-nowrap hover:bg-primary hover:text-white transition duration-300 px-3 py-2 rounded-lg"
              onClick={logoutHandler}
            >
              Logout
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
