import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
  Grid,
  Dialog,
  DialogContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { MdCheckCircle } from "react-icons/md";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { closeFeedback } from "../../GlobalRedux/Features/GlobalStateSlice";
import userData from "../../services/userData";

const OverallFeedback = () => {
  const [finalFeedbackSubmitted, setFinalFeedbackSubmitted] = useState(false);
  const [feedback, setFeedback] = useState({
    q1: null,
    q2: "",
    q3: null,
    q4: "",
    q5: "",
    q6: "",
    q7: "",
  });
  const colors = ["red", "orange", "yellow", "green", "blue"]; // Color scheme for radio buttons

  useEffect(() => {
    // Check if feedback was submitted earlier
    setFinalFeedbackSubmitted(false);
  }, []);

  const isSaveEnabled = feedback.q1 !== null && feedback.q3 !== null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedback((prevFeedback) => ({
      ...prevFeedback,
      [name]: value,
    }));
  };

  const [price, setPrice] = useState({
    pr: 0,
    currency: "INR",
    type: "Monthly",
  });

  const saveFeedback = async () => {
    setFinalFeedbackSubmitted(true);
    const points = feedback.q1 || 0;
    const token = Cookies.get("token");

    const overallFeedbackData = {
      email: "sample@example.com",
      overall: {
        question1: {
          text: "How would you rate the overall performance and working of the tool?",
          answer: feedback.q1,
        },
        question2: {
          text: "What more features would you like to have in a tool like this?",
          answer: feedback.q2,
        },
        question3: {
          text: "Would you buy the subscription to this tool by paying a fee?",
          answer: feedback.q3,
        },
        question4: {
          text: "How much would you be willing to pay monthly for this tool?",
          answer: price.currency + ' ' + price.type + ' ' + price.pr,
        },
        question5: {
          text: "Why are you not willing to pay?",
          answer: feedback.q5,
        },
        question6: {
          text: "Are there alternatives to this tool that you find more suitable for your needs? Could you name them?",
          answer: feedback.q6,
        },
      },
      points: points,
      token,
    };

    try {
      const res = await userData.storeFeedback("overall", overallFeedbackData);
      console.log("Data submitted successfully:", res.data);
      setTimeout(() => {
        dispatch(closeFeedback("overall"));
      }, 1000);
    } catch (error) {
      console.error("Error storing feedback:", error);
    }
  };

  const dispatch = useDispatch();
  const overall = useSelector((state) => state.GlobalState.overall);

  return (
    <Dialog
      open={Boolean(overall)}
      onClose={() => dispatch(closeFeedback("overall"))}
      className=""
    >
      <DialogContent>
        {!finalFeedbackSubmitted ? (
          <Card elevation={0}>
            <CardHeader title="Final Feedback" />
            <Grid container spacing={2} className="">
              {/* Question 1 */}
              <Grid item xs={12}>
                <Typography>
                  How would you rate the overall performance and working of the
                  tool?
                </Typography>
                <RadioGroup
                  name="q1"
                  value={feedback.q1}
                  onChange={handleChange}
                  row
                >
                  {[...Array(5)].map((_, index) => (
                    <FormControlLabel
                      key={index}
                      value={index}
                      control={<Radio style={{ color: colors[index] }} />}
                      label={index.toString()}
                    />
                  ))}
                </RadioGroup>
              </Grid>

              {/* Question 2 */}
              <Grid item xs={12}>
                <Typography>
                  What other features would you like to have in this tool?
                </Typography>
                <TextField
                  name="q2"
                  value={feedback.q2}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  InputProps={{ notched: true }}
                />
              </Grid>

              {/* Question 3 */}
              <Grid item xs={12}>
                <Typography>
                  Would you buy the subscription to this tool by paying a fee?
                </Typography>
                <RadioGroup
                  name="q3"
                  value={feedback.q3}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </Grid>

              {/* Conditional Question 4 */}
              {feedback.q3 === "yes" && (
                <Grid item xs={12}>
                  <Typography>
                    How much would you be willing to pay monthly for this tool?
                  </Typography>
                  <div className="grid grid-cols-2 gap-4 p-5 my-5 bg-primary/10 rounded-xl">
                    <FormControl variant="outlined" margin="normal">
                      <InputLabel>Currency</InputLabel>
                      <Select
                        name="currency"
                        value={price.currency}
                        onChange={(e) =>
                          setPrice((prev) => {
                            return {
                              ...prev,
                              currency: e.target.value,
                            };
                          })
                        }
                        label="Currency"
                      >
                        <MenuItem value="USD">USD</MenuItem>
                        <MenuItem value="EUR">EUR</MenuItem>
                        <MenuItem value="GBP">GBP</MenuItem>
                        <MenuItem value="INR">INR</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl variant="outlined" margin="normal">
                      <InputLabel>Subscription type</InputLabel>
                      <Select
                        name="type"
                        value={price.type}
                        onChange={(e) =>
                          setPrice((prev) => {
                            return {
                              ...prev,
                              type: e.target.value,
                            };
                          })
                        }
                        label="Subscription Type"
                      >
                        <MenuItem value="Monthly">Monthly</MenuItem>
                        <MenuItem value="Yearly">Yearly</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl variant="outlined" margin="normal" fullWidth>
                      <InputLabel htmlFor="amount-input">Amount</InputLabel>
                      <OutlinedInput
                        id="amount-input"
                        value={price.pr}
                        onChange={(e) =>
                          setPrice((prev) => ({
                            ...prev,
                            pr: e.target.value,
                          }))
                        }
                        type="number"
                        inputProps={{ min: 0, max: 100000000 }} // Set range 0-100 million
                        label="Amount" // Ensure label is correctly associated
                      />
                    </FormControl>
                  </div>
                </Grid>
              )}

              {/* Conditional Question 5 */}
              {feedback.q3 === "no" && (
                <Grid item xs={12}>
                  <Typography>What are your reasons for not buying the subscription, could you explain more?</Typography>
                  <TextField
                    name="q5"
                    value={feedback.q5}
                    onChange={handleChange}
                    multiline
                    rows={2}
                    variant="outlined"
                    fullWidth
                    InputProps={{ notched: true }}
                  />
                </Grid>
              )}

              {/* Question 6 */}
              <Grid item xs={12}>
                <Typography>
                  Are there alternatives to this tool that you find more
                  suitable for your needs? Could you name them?
                </Typography>
                <TextField
                  name="q6"
                  value={feedback.q6}
                  onChange={handleChange}
                  multiline
                  rows={2}
                  variant="outlined"
                  fullWidth
                  InputProps={{ notched: true }}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  onClick={saveFeedback}
                  disabled={!isSaveEnabled}
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Card>
        ) : (
          <div className="feedback-submitted-card">
            <CardHeader
              avatar={<MdCheckCircle style={{ color: "#4caf50" }} />}
              title="Feedback Submitted"
              titleTypographyProps={{ variant: "h6" }}
            />
            <CardContent>
              <Typography>
                Thank you for your feedback! It has been successfully submitted.
              </Typography>
            </CardContent>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default OverallFeedback;
