import React, { useEffect, useState } from "react";
import Markdown from "react-markdown";
import { useNavigate } from "react-router";
import { Link, NavLink } from "react-router-dom";
import userData from "../services/userData";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import AppuComponent from "../components/AskAppu";
import { useDispatch } from "react-redux";
import { setDynamicId } from "../GlobalRedux/Features/GlobalStateSlice";
import Cookies from 'js-cookie'
function ImproveCV() {
  const [aiImprovements, setAiImprovements] = useState(
    JSON.parse(localStorage.getItem("ai_improvements"))
  );
  const [loading, setLoading] = useState(false);
  const [summarizedText, setSummarizedText] = useState("");
  const [appuEnable, setAppuEnable] = useState(false);
  const [showFeedback, setShowFeedback] = useState(0);

  const dispatch = useDispatch();

  const evaluationText = () => {
    let text = "";
    let i = 1;
    while (aiImprovements[`improvement ${i}`]) {
      text += aiImprovements[`improvement ${i}`];
      i++;
    }
    return text;
  };

  const getAppuSpeech = async () => {
    try {
      if (summarizedText) return;
      const res = await userData.summarizeText(evaluationText());
      const preText = "Thanks for asking me. ";
      const postText =
        "You can now access your dashboard to make further edits or updates as needed. Once satisfied with the changes, you can download the improved CV for additional evaluation. Best of luck with your review!";
      setSummarizedText(preText + res.data?.summary + postText);
    } catch (err) {
      console.log(err);
    }
  };

  const GenerateMarkdown = ({ str }) => {
    console.log("Received string = ", str);
    if (typeof str === "string") return <Markdown>{str}</Markdown>;
  };

  async function getDynamic() {
    try {
      const token = Cookies.get("token");
      if (token) {
        const lastDataResponse = await userData.checkLastData(token);
        const id = lastDataResponse.data.id || 0;
        dispatch(setDynamicId(id));
        return id;
      }
    } catch (err) {
      console.log(err);
    }
  }

  const navigate = useNavigate();

  const goToDashboard = async () => {
    try{
        const id = await getDynamic();
        navigate(`/user-home/${id}`);
    } catch(err){
      console.log(err);
    }
  }

  const downloadImprovementReport = async () => {
    try {
      const data = {
        insights: aiImprovements,
      };
      setLoading(true);
      const response = await userData.getImprovementReport(data);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "improvement_report.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      console.error("Error while downloading CV", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(Object.entries(aiImprovements));
  }, [aiImprovements]);

  return (
    <div className="grid grid-cols-5 font-sans">
      <div className="col-span-3 flex-1 flex flex-col items-center ">
        <div className="shadow-md shadow-gray-300 mx-2 py-6 px-3">
          <p className="font-bold text-2xl">Improvements made by PARINAAM:</p>
          <p className="secondary-text">
            Please do share your feedback for us to improve.
          </p>

          <div className="flex justify-between items-center p-5 bg-white w-full max-w-7xl">
            <div>
              <button
                disabled={loading}
                onClick={downloadImprovementReport}
                className="secondary-button bg-white py-3 px-5 rounded-full text-sm text-[#0E0066] font-bold border-2 border-[#0E0066] mr-4"
              >
                Download Report
              </button>
              <button
                onClick={() => {
                  setAppuEnable(true);
                  getAppuSpeech();
                }}
                className="primary-button bg-white py-3 px-5 rounded-full text-sm text-[#0E0066] font-bold border-2 border-[#0E0066]"
              >
                ASK APPU
              </button>
            </div>
          </div>
          {aiImprovements &&
            Object.entries(aiImprovements).map((arr, index) => {
              const [title, description] = arr;
              if (!description) return null; // Skip rendering if description is empty
              return (
                <div key={index} className="mt-4 text-sm">
                  <p className="text-primary-bold font-bold text-lg">
                    {title.charAt(0).toUpperCase() + title.slice(1)}
                  </p>
                  <p className="text-secondary-text font-semibold">
                    <GenerateMarkdown str={description} />
                  </p>
                </div>
              );
            })}
          <p className="mt-5 text-2xl font-bold ">Improvement summary:</p>
          <p className="font-semibold text-secondary-text text-sm">
            {" "}
            Enhances your resume by aligning it with industry standards and
            job-specific requirements. Focus on highlighting key skills,
            relevant experiences, and quantifiable achievements that match the
            job description. Tailor your content to showcase how you can add
            value to the role you're applying for.
          </p>
        </div>
      </div>
      <div className="col-span-2 px-3 py-6">
        <div>
          <p className="text-lg font-bold mb-5">
            {" "}
            CV has been improved and dashboard is updated
          </p>
          <button onClick={goToDashboard} className="secondary-button">
            Dashboard
          </button>
        </div>
        <img src="/mountain_top.svg" alt="Results compiled" />
      </div>
      <Dialog
        open={appuEnable}
        onClose={() => setAppuEnable(false)}
        maxWidth={false}
        className="w-[90vw] mx-auto"
      >
        <DialogTitle className="font-semibold text-primary-bold text-center">
          Hi, I am Appu!
        </DialogTitle>
        <DialogContent className="">
          <AppuComponent text={summarizedText} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ImproveCV;
