import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Navbar from "./components/Navbar";
import Sidenav from "./components/Sidenav";
import UserHome from "./pages/UserHome";
import InterviewRoom from "./pages/InterviewRoom";
import FileUpload from "./pages/FileUpload";
import ResultEvaluate from "./pages/ResultEvaluate";
import ResultImprove from "./pages/ResultImprove";
import DownloadCVDialog from "./components/DownloadCVDialog";
import EditProfile from "./components/EditProfile";
import Feedback from "./pages/Feedback";
import EvaluateFeedback from "./components/FeedbackComponents/EvaluateFeedback";
import ImproveFeedback from "./components/FeedbackComponents/ImproveFeedback";
import InterviewFeedback from "./components/FeedbackComponents/InterviewFeedback";
import BuildFeedback from "./components/FeedbackComponents/BuildFeedback";
import OverallFeedback from "./components/FeedbackComponents/OverallFeedback";
import FirstProfile from "./components/FirstProfile";
import AdminPortal from "./components/AdminPortalComponents/AdminPortal";
import ChangePassword from "./pages/ChangePassword";
import { useEffect } from "react";

function App() {


  const imageUrls = [
    `${process.env.PUBLIC_URL}/template_0.png`,
    `${process.env.PUBLIC_URL}/template_1.png`,
    `${process.env.PUBLIC_URL}/template_2.png`,
    `${process.env.PUBLIC_URL}/doyouhvcv.gif`,
    `${process.env.PUBLIC_URL}/CV.png`,
    `${process.env.PUBLIC_URL}/Step3.jpg`,
  ];

  const preloadImages = (urls) => {
    urls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  };

  useEffect(() => {
    preloadImages(imageUrls);
  } , [])

  const location = useLocation();
  const noNavPaths = ["/", "/login", "/register"];



  return (
    <div>
      <DownloadCVDialog />
      <EvaluateFeedback />
      <InterviewFeedback />
      <BuildFeedback />
      <ImproveFeedback />
      <OverallFeedback />
      <div className="min-h-[100vh] max-w-[100vw] overflow-x-hidden">
        <div className="flex gap-5 min-h-screen px-8 py-5">
          {!noNavPaths.includes(location.pathname) && <Sidenav />}
          <div className=" flex-grow">
            {!noNavPaths.includes(location.pathname) && <Navbar />}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Signup />} />
              <Route path="/interview-room" element={<InterviewRoom />} />
              <Route path="/user-home/:id" element={<UserHome />} />
              <Route path="/edit-profile" element={<EditProfile />} />
              <Route
                path="/file-upload/:service/:method"
                element={<FileUpload />}
              />
              <Route path="/result/evaluate" element={<ResultEvaluate />} />
              <Route path="/result/improve" element={<ResultImprove />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route path='/profile' element={<FirstProfile />} />
              <Route path='/admin-portal' element={<AdminPortal />} />
              <Route path="/change-password" element={<ChangePassword />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
