import React from 'react'
import Dashboard from '../components/Dashboard'


function Banner(){
  return (

    <div className='w-full primary-gradient px-8 py-8 mt-5 rounded-lg text-2xl font-semibold'>
      <p>Hi, welcome back!</p>
      <p>How can we help you today?</p>
    </div>

  )
}

function UserHome() {
  return (
    <div >
      <Banner />
      <Dashboard />
    </div>
  )
}

export default UserHome
