import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: {
    email: "",
    userHandle: "",
    name: "",
    avatar: "",
    cv_dialog: false,
    dynamic_id: 0,
    evaluate: false,
    improve: false,
    build: false,
    interview: false,
    overall: false,
  },
  navText: [],
};

export const GlobalStateSlice = createSlice({
  name: "globalState",
  initialState,

  reducers: {
    setNavText: (state, value) => {
      state.navText = value.payload;
      return state;
    },
    setUserDetails: (state, action) => {
      state.userDetails = {
        ...state.userDetails,
        ...action.payload,
      };
      return state;
    },
    setCVDialog: (state, action) => {
      state.cv_dialog = action.payload;
      return state;
    },
    setDynamicId: (state, action) => {
      state.dynamic_id = action.payload;
      return state;
    },
    openFeedback: (state, action) => {
      console.log(action.payload , 'Opening feedback');
      state[action.payload] = true;
      return state;
    },
    closeFeedback: (state, action) => {
      state[action.payload] = false;
      return state;
    },
  },
});

export const {
  setUserDetails,
  setNavText,
  setCVDialog,
  setDynamicId,
  openFeedback,
  closeFeedback,
} = GlobalStateSlice.actions;
export default GlobalStateSlice.reducer;
