import React, { useEffect, useState } from "react";
import * as d3 from "d3";

function SpiderMap({ score }) {
  const [metrics, setMetrics] = useState(null);

  useEffect(() => {
    if (score) {
        console.log(score);
      const tempObj = {
        skills: { Quantity: score[0], Relevance: score[1], Quality: score[2] },
        qualification: {
          Quantity: score[3],
          Relevance: score[4],
          Quality: score[5],
        },
        experience: {
          Quantity: score[6],
          Relevance: score[7],
          Quality: score[8],
        },
      };
      setMetrics(tempObj);
    }
  }, [score]);

  useEffect(() => {
    if (metrics) {
      generateChart();
    }
  }, [metrics]);

  function generateChart() {
    const data = [
      {
        category: "Qualification",
        metrics: metrics.qualification,
      },
      {
        category: "Skills",
        metrics: metrics.skills,
      },
      {
        category: "Experience",
        metrics: metrics.experience,
      },
    ];

    d3.select("#chart").select("svg").remove();

    const width = 600;
    const height = 600;
    const margin = { top: 100, right: 100, bottom: 100, left: 100 };

    const svg = d3
      .select("#chart")
      .style("display", "block")
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr(
        "transform",
        `translate(${width / 2 + margin.left}, ${height / 2 + margin.top})`
      );

    const radius = Math.min(width / 2, height / 2);
    const levels = 5;
    const maxValue = 100;
    const allAxis = ["Quantity", "Relevance", "Quality"];
    const total = allAxis.length;
    const angleSlice = (Math.PI * 2) / total;
    const rScale = d3.scaleLinear().range([0, radius]).domain([0, maxValue]);

    const axisGrid = svg.append("g").attr("class", "axisWrapper");

    axisGrid
      .selectAll(".levels")
      .data(d3.range(1, levels + 1).reverse())
      .enter()
      .append("circle")
      .attr("class", "gridCircle")
      .attr("r", (d) => (radius / levels) * d)
      .style("fill", "#CDCDCD")
      .style("stroke", "#CDCDCD")
      .style("fill-opacity", 0.1);

    axisGrid
      .selectAll(".axisLabel")
      .data(d3.range(1, levels + 1).reverse())
      .enter()
      .append("text")
      .attr("class", "axisLabel")
      .attr("x", 4)
      .attr("y", (d) => (-d * radius) / levels)
      .attr("dy", "0.4em")
      .style("font-size", "10px")
      .attr("fill", "black")
      .text((d) => (d * maxValue) / levels);

    const axis = axisGrid
      .selectAll(".axis")
      .data(allAxis)
      .enter()
      .append("g")
      .attr("class", "axis");

    axis
      .append("line")
      .attr("x1", 0)
      .attr("y1", 0)
      .attr(
        "x2",
        (d, i) =>
          rScale(maxValue * 1.1) * Math.cos(angleSlice * i - Math.PI / 2)
      )
      .attr(
        "y2",
        (d, i) =>
          rScale(maxValue * 1.1) * Math.sin(angleSlice * i - Math.PI / 2)
      )
      .style("stroke", "black")
      .style("stroke-width", "2px");

    axis
      .append("text")
      .attr("class", "legend")
      .style("font-size", "18px")
      .style("font-weight", "bold")
      .attr("text-anchor", "middle")
      .attr("dy", "0.35em")
      .attr(
        "x",
        (d, i) =>
          rScale(maxValue * 1.25) * Math.cos(angleSlice * i - Math.PI / 2)
      )
      .attr(
        "y",
        (d, i) =>
          rScale(maxValue * 1.25) * Math.sin(angleSlice * i - Math.PI / 2)
      )
      .text((d) => d);

    const radarLine = d3
      .lineRadial()
      .curve(d3.curveLinearClosed)
      .radius((d) => rScale(d.value))
      .angle((d, i) => i * angleSlice);

    const radarWrapper = svg
      .selectAll(".radarWrapper")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "radarWrapper");

    radarWrapper
      .append("path")
      .attr("class", "radarArea")
      .attr("d", (d) =>
        radarLine(
          Object.keys(d.metrics).map((key, i) => ({
            axis: key,
            value: d.metrics[key],
          }))
        )
      )
      .style("fill", "none")
      .style("stroke", (d) => getColor(d.category))
      .style("stroke-width", "2px")
      .style("fill-opacity", 0)
      .on("mouseover", function (event, d) {
        highlightArea(d.category, event.target);
        d3.selectAll(".radarArea")
          .transition()
          .duration(200)
          .style("fill-opacity", 0.1);
        d3.select(event.target)
          .transition()
          .duration(200)
          .style("fill-opacity", 0.7)
          .style("fill", "#CDCDCD");

        this.parentNode.appendChild(this);

        const points = Object.keys(d.metrics).map((key, i) => ({
          axis: key,
          value: d.metrics[key],
          x: rScale(d.metrics[key]) * Math.cos(angleSlice * i - Math.PI / 2),
          y: rScale(d.metrics[key]) * Math.sin(angleSlice * i - Math.PI / 2),
        }));

        points.forEach((point) => {
          const tooltipText = `${point.axis}: ${point.value}`;
          const tooltipWidth = getTooltipWidth(tooltipText);
          const tooltipHeight = getTooltipHeight();

          svg
            .append("rect")
            .attr("class", "tooltipBox")
            .attr(
              "x",
              point.axis === "Quality"
                ? point.x - tooltipWidth - 10
                : point.axis === "Relevance"
                ? point.x + 10
                : point.x - tooltipWidth / 2
            )
            .attr("y", point.y - tooltipHeight - 10)
            .attr("width", tooltipWidth)
            .attr("height", tooltipHeight)
            .attr("rx", 5)
            .attr("ry", 5)
            .style("fill", "lightsteelblue")
            .style("opacity", 0.9);

          svg
            .append("text")
            .attr("class", "tooltipText")
            .attr(
              "x",
              point.axis === "Quality"
                ? point.x - 10 - tooltipWidth / 2
                : point.axis === "Relevance"
                ? point.x + 10 + tooltipWidth / 2
                : point.x
            )
            .attr("y", point.y - tooltipHeight / 2)
            .attr("dy", "-6px")
            .style("font-size", "14px")
            .style("font-weight", "500")
            .style("text-anchor", "middle")
            .style("pointer-events", "none")
            .attr("fill", "black")
            .text(tooltipText);
        });
      })
      .on("mouseout", function (event, d) {
        d3.select(event.target)
          .transition()
          .duration(200)
          .style("fill-opacity", 0);
        resetHighlight(d.category);
        svg.selectAll(".tooltipBox").remove();
        svg.selectAll(".tooltipText").remove();
      });

    radarWrapper
      .selectAll(".radarCircle")
      .data((d) =>
        Object.keys(d.metrics).map((key, i) => ({
          axis: key,
          value: d.metrics[key],
          category: d.category,
        }))
      )
      .enter()
      .append("circle")
      .attr("class", "radarCircle")
      .attr("r", 5)
      .attr(
        "cx",
        (d, i) => rScale(d.value) * Math.cos(angleSlice * i - Math.PI / 2)
      )
      .attr(
        "cy",
        (d, i) => rScale(d.value) * Math.sin(angleSlice * i - Math.PI / 2)
      )
      .style("fill", (d) => getColor(d.category))
      .style("fill-opacity", 0.8)
      .on("mouseover", function (event, d) {
        const element = d3.select(event.target);

        const cx = element.attr("cx");
        const cy = element.attr("cy");
        d3.selectAll(".tooltipBox").remove();
        d3.selectAll(".tooltipText").remove();

        highlightArea(d.category, event.target);

        d3.select(event.target)
          .transition()
          .duration(200)
          .style("fill", "#FFA500");

        const tooltipText = `${d.category}: ${d.value}`;
        const tooltipWidth = getTooltipWidth(tooltipText);
        const tooltipHeight = getTooltipHeight();

        svg
          .append("rect")
          .attr("class", "tooltipBox")
          .attr("x", +cx - tooltipWidth / 2)
          .attr("y", +cy - tooltipHeight - 10)
          .attr("width", tooltipWidth)
          .attr("height", tooltipHeight)
          .attr("rx", 5)
          .attr("ry", 5)
          .style("fill", "lightsteelblue")
          .style("opacity", 0.9);

        svg
          .append("text")
          .attr("class", "tooltipText")
          .attr("x", +cx)
          .attr("y", +cy - tooltipHeight / 2)
          .attr("dy", "-6px")
          .style("font-size", "14px")
          .style("font-weight", "500")
          .style("text-anchor", "middle")
          .style("pointer-events", "none")
          .attr("fill", "black")
          .text(tooltipText);

        highlightAxisLabel(d.axis);
      })

      .on("mouseout", function (event, d) {
        d3.select(event.target)
          .transition()
          .duration(200)
          .style("fill", getColor(d.category));
        resetHighlight(d.category);
        svg.selectAll(".tooltipBox").remove();
        svg.selectAll(".tooltipText").remove();

        resetAxisLabel(d.axis);
      });

    const legend = svg
      .append("g")
      .attr("class", "legend")
      .attr("transform", `translate(${width / 2 - 80}, ${-height / 2 - 50})`);

    legend
      .selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .attr("id", (d) => `legendRect-${d.category}`)
      .attr("x", 0)
      .attr("y", (d, i) => i * 30)
      .attr("width", 20)
      .attr("height", 20)
      .style("fill", (d) => getColor(d.category))
      .on("mouseover", (event, d) => {
        highlightArea(d.category, event.target);
        d3.select(event.target)
          .transition()
          .duration(200)
          .attr("width", 30)
          .attr("height", 22)
          .style("fill", "#FFA500");

        const points = Object.keys(d.metrics).map((key, i) => ({
          axis: key,
          value: d.metrics[key],
          x: rScale(d.metrics[key]) * Math.cos(angleSlice * i - Math.PI / 2),
          y: rScale(d.metrics[key]) * Math.sin(angleSlice * i - Math.PI / 2),
        }));

        points.forEach((point) => {
          const tooltipText = `${point.axis}: ${point.value}`;
          const tooltipWidth = getTooltipWidth(tooltipText);
          const tooltipHeight = getTooltipHeight();

          svg
            .append("rect")
            .attr("class", "tooltipBox")
            .attr(
              "x",
              point.axis === "Quality"
                ? point.x - tooltipWidth - 10
                : point.axis === "Relevance"
                ? point.x + 10
                : point.x - tooltipWidth / 2
            )
            .attr("y", point.y - tooltipHeight - 10)
            .attr("width", tooltipWidth)
            .attr("height", tooltipHeight)
            .attr("rx", 5)
            .attr("ry", 5)
            .style("fill", "lightsteelblue")
            .style("opacity", 0.9);

          svg
            .append("text")
            .attr("class", "tooltipText")
            .attr(
              "x",
              point.axis === "Quality"
                ? point.x - 10 - tooltipWidth / 2
                : point.axis === "Relevance"
                ? point.x + 10 + tooltipWidth / 2
                : point.x
            )
            .attr("y", point.y - tooltipHeight / 2)
            .attr("dy", "-6px")
            .style("font-size", "14px")
            .style("font-weight", "500")
            .style("text-anchor", "middle")
            .style("pointer-events", "none")
            .attr("fill", "black")
            .text(tooltipText);
        });
        highlightAxisLabel(d.axis);
      })
      .on("mouseout", function (event, d) {
        resetHighlight(d.category);
        d3.select(event.target)
          .transition()
          .duration(200)
          .attr("width", 20)
          .attr("height", 20)
          .style("fill", getColor(d.category));
        resetHighlight(d.category);
        svg.selectAll(".tooltipBox").remove();
        svg.selectAll(".tooltipText").remove();

        resetAxisLabel(d.axis);
      });

    legend
      .selectAll("text")
      .data(data)
      .enter()
      .append("text")
      .attr("id", (d) => `legendText-${d.category}`)
      .attr("x", 30)
      .attr("y", (d, i) => i * 30 + 14)
      .style("cursor", "pointer")
      .text((d) => d.category)
      .style("font-size", "20px")
      .style("font-weight", "400")
      .attr("fill", (d) => getColor(d.category))
      .on("mouseover", (event, d) => {
        highlightArea(d.category, event.target);

        const points = Object.keys(d.metrics).map((key, i) => ({
          axis: key,
          value: d.metrics[key],
          x: rScale(d.metrics[key]) * Math.cos(angleSlice * i - Math.PI / 2),
          y: rScale(d.metrics[key]) * Math.sin(angleSlice * i - Math.PI / 2),
        }));

        points.forEach((point) => {
          const tooltipText = `${point.axis}: ${point.value}`;
          const tooltipWidth = getTooltipWidth(tooltipText);
          const tooltipHeight = getTooltipHeight();

          svg
            .append("rect")
            .attr("class", "tooltipBox")
            .attr("x", point.x - tooltipWidth / 2)
            .attr("y", point.y - tooltipHeight - 10)
            .attr("width", tooltipWidth)
            .attr("height", tooltipHeight)
            .attr("rx", 5)
            .attr("ry", 5)
            .style("fill", "lightsteelblue")
            .style("opacity", 0.9);

          svg
            .append("text")
            .attr("class", "tooltipText")
            .attr("x", point.x)
            .attr("y", point.y - tooltipHeight / 2)
            .attr("dy", "-6px")
            .style("font-size", "14px")
            .style("text-anchor", "middle")
            .style("pointer-events", "none")
            .attr("fill", "black")
            .style("font-weight", "500")
            .text(tooltipText);
        });
      })
      .on("mouseout", function (event, d) {
        resetHighlight(d.category);
        svg.selectAll(".tooltipBox").remove();
        svg.selectAll(".tooltipText").remove();
      });
  }

  function getColor(category) {
    if (category === "Qualification") return "red";
    if (category === "Skills") return "blue";
    if (category === "Experience") return "green";
  }

  function getTooltipWidth(text) {
    const testSVG = d3.select("body").append("svg").attr("class", "testSVG");
    const testText = testSVG
      .append("text")
      .attr("x", -99999)
      .attr("y", -99999)
      .text(text);
    const bbox = testText.node().getBBox();
    testSVG.remove();
    return +bbox.width;
  }

  function getTooltipHeight() {
    return 30;
  }

  function highlightArea(category, element) {
    d3.selectAll(".radarArea")
      .style("stroke", (d) =>
        d.category === category ? "#FFA500" : getColor(d.category)
      )
      .style("fill-opacity", (d) => (d.category === category ? 0.7 : 0));

    d3.selectAll(".radarCircle").style("fill", (d) =>
      d.category === category ? "#FFA500" : getColor(d.category)
    );

    d3.select(`#legendText-${category}`)
      .style("font-weight", "bold")
      .style("font-size", "22px")
      // .style("fill", "#FFA500")
      .attr("x", 32);

    d3.select(`#legendRect-${category}`)
      .style("stroke", "#FFA500")
      .style("fill", "none")
      .attr("width", 30)
      .attr("height", 20);

    if (!element || !element.parentNode) return;
    element.parentNode.appendChild(element);
  }

  function resetHighlight(category) {
    d3.selectAll(".radarArea")
      .style("stroke", (d) => getColor(d.category))
      .style("fill-opacity", 0);

    d3.selectAll(".radarCircle").style("fill", (d) => getColor(d.category));

    d3.select(`#legendText-${category}`)
      .style("fill", getColor(category))
      .style("font-weight", "400")
      .style("font-size", "20px")
      .attr("x", 30);
    d3.select(`#legendRect-${category}`)
      .style("stroke", "none")
      .style("fill", getColor(category))
      .attr("width", 20)
      .attr("height", 20);
  }

  function highlightAxisLabel(axis) {
    d3.selectAll(".legend")
      .filter((d) => d === axis)
      .transition()
      .duration(200)
      .style("fill", "#FFA500");
  }

  function resetAxisLabel(axis) {
    d3.selectAll(".legend")
      .filter((d) => d === axis)
      .transition()
      .duration(200)
      .style("fill", "#000");
  }

  return (
    <div className="scale-[0.85] -translate-y-16">
      <div className="container">
        <div id="chart"></div>
      </div>
    </div>
  );
}

export default SpiderMap;
