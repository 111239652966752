import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCVDialog } from "../GlobalRedux/Features/GlobalStateSlice";
import { IoClose } from "react-icons/io5";
import ColorPalette from "./ColorPalette";
import userData from "../services/userData";
import { useParams } from "react-router";
import { Loading } from "../pages/InterviewRoom";

function DownloadCVDialog() {
  const dispatch = useDispatch();
  const cv_dialog = useSelector((state) => state.GlobalState.cv_dialog);

  const [selectedCV, setSelectedCV] = useState(null);

  const [downloading, setDownloading] = useState(false);

  const [cvTheme, setCVTheme] = useState({
    fontColor: "#f4bf43",
    backgroundColor: "#fdd390",
  });

  const onColorChange = (data) => {
    setCVTheme(data);
  };

  const id = useSelector((state) => state.GlobalState.dynamic_id);

  const downloadCV = async () => {
    try {
      if (!id) {
        alert("Please build your CV first");
        return;
      }
      setDownloading(true);
      const data = {
        id: id,
        template_id: selectedCV + 1,
        theme: cvTheme,
      };
      const response = await userData.downloadCV(data);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "resume.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
      setSelectedCV(null);
      dispatch(setCVDialog(false));
    } catch (err) {
      console.error("Error while downloading CV", err);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <Dialog
      open={Boolean(cv_dialog)}
      sx={{ width: "95vw" }}
      maxWidth={false}
      fullWidth
      className="pt-12 bg-white flex flex-col transition duration-500 fixed mx-auto"
    >
      <DialogTitle>
        <p className="text-xl font-bold text-primary-text text-center">
          Choose a template
        </p>
        <p className="text-secondary-text text-sm font-semibold text-center">
          Choose a template, select theme and download your CV
        </p>
        <IconButton
          aria-label="close"
          onClick={() => dispatch(setCVDialog(false))}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <IoClose />
        </IconButton>
      </DialogTitle>

      <DialogContent className="px-7">
        <div className="grid grid-cols-3 gap-12 justify-center my-7 md:px-12 lg:px-20">
          <img
            src="/template_0.png"
            alt="template-1"
            onClick={() => setSelectedCV(0)}
            className={`cursor-pointer border-2 max-h-[450px] h-full w-full aspect-auto rounded-lg ${
              selectedCV === 0
                ? " border-blue-400 border-4 shadow-lg shadow-gray-400"
                : "border-gray-400"
            }`}
          />
          <img
            src="/template_1.png"
            alt="template-2"
            onClick={() => setSelectedCV(1)}
            className={`cursor-pointer border-2 max-h-[450px] h-full w-full aspect-auto rounded-lg ${
              selectedCV === 1
                ? " border-blue-400 border-4 shadow-lg shadow-gray-400"
                : "border-gray-400"
            }`}
          />
          <img
            src="/template_2.png"
            alt="template-3"
            onClick={() => setSelectedCV(2)}
            className={`cursor-pointer border-2 max-h-[450px] h-full w-full aspect-auto rounded-lg ${
              selectedCV === 2
                ? " border-blue-400 border-4 shadow-lg shadow-gray-400"
                : "border-gray-400"
            }`}
          />
        </div>

        <div className="flex justify-center gap-40 px-12 mt-4 flex-wrap lg:flex-nowrap">
          <ColorPalette onColorChange={onColorChange} />

          {!downloading && (
            <button
              className="primary-button max-h-[50px]"
              onClick={downloadCV}
              disabled={(selectedCV === null)}
            >
              Download
            </button>
          )}
          {downloading && (
            <div className="flex gap-2 items-center">
              <Loading />
              Downloading...
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default DownloadCVDialog;
