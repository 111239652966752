import React from 'react'

function Avatar({ username }) {
    // Generate a random color
    const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);

    // Extract the first two words from the username
    const words = username.split(' ');
    const initials = words.length >= 2 ? words[0][0] + words[1][0] : words[0][0];

    return (
        <div
            style={{
                backgroundColor: randomColor,
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#fff',
                fontWeight: 'bold',
                fontSize: '20px',
            }}
        >
            {initials}
        </div>
    );
}

export default Avatar;
